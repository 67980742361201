import * as React from "react"
import Layout from "../components/layout"
import Seo from "../components/seo"
import Button from "../components/button"
import styled from "styled-components"

import {
  Grid,
  Box,
  Heading,
  Text,
  TextInput,
  Paragraph,
  Card,
  CardBody,
  ResponsiveContext,
} from "grommet"

import { User, Lock } from "grommet-icons"

const StyledCard = styled(Card)`
  max-width: 90vw;
  margin: 0 auto;

  label {
    color: white;
  }
`

const StyledLink = styled.a`
  color: white;
  &:hover {
    text-decoration: underline;
  }
`

const StyledLabel = styled.label`
  padding: 0 0 10px;
`

const Provider = ({ children }) => {
  const size = React.useContext(ResponsiveContext)
  return (
    <Box fill align="center" justify="center">
      <StyledCard>
        <CardBody>
          <Grid columns={["1fr", "1fr"]} rows={["1fr"]}>
            <Box pad={size}>
              <Heading size="small">Welcome to EcoMATE Cloud</Heading>
              <Text weight={500}>IMO and MRV Compliance made simple</Text>
              <Paragraph>
                Can't log in to the new cloud app? Log in to the old cloud and
                request access by April 1st 2022
              </Paragraph>
            </Box>
            <Box
              background="linear-gradient(296.5deg, #005DA8 20.33%, #2582CD 67.52%)"
              pad="large"
            >
              <Heading level={3} size="small" color="white">
                <strong>Log in to your account</strong>
              </Heading>

              <form method="post" action="https://app.ecomate.cloud/login">
                <Box margin={{ bottom: "small" }}>
                  <StyledLabel for="user">E-mail or username</StyledLabel>
                  <TextInput id="user" name="user" icon={<User />} />
                </Box>
                <Box>
                  <StyledLabel for="password">Password</StyledLabel>
                  <TextInput
                    id="password"
                    type="password"
                    name="password"
                    icon={<Lock />}
                  />
                </Box>
                <Paragraph>
                  <Button type="submit" label="Log in" />
                </Paragraph>
              </form>
              <Text textAlign="end">
                <StyledLink href="https://app.ecomate.cloud/user/password/send-reset-email">
                  Forgot your password?
                </StyledLink>
              </Text>
            </Box>
          </Grid>
        </CardBody>
      </StyledCard>
    </Box>
  )
}

const IndexPage = () => (
  <Layout>
    <Seo title="Login" />
    <Provider></Provider>
  </Layout>
)

export default IndexPage
